@import "tailwindcss/base";
/* ----- Custom base styles ----- */
@import "./base.css";

@import "tailwindcss/components";
/* ----- Custom components ----- */
@import "./components.css";

@import "tailwindcss/utilities";
/* ----- Custom utilities ----- */
@font-face {
    font-family: 'Muli';
    src: url('/fonts/Muli-Bold.eot');
    src: url('/fonts/Muli-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Muli-Bold.woff2') format('woff2'),
    url('/fonts/Muli-Bold.ttf') format('truetype'),
    url('/fonts/Muli-Bold.svg#Muli-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('/fonts/Muli-Regular.eot');
    src: url('/fonts/Muli-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Muli-Regular.woff2') format('woff2'),
    url('/fonts/Muli-Regular.ttf') format('truetype'),
    url('/fonts/Muli-Regular.svg#Muli-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('/fonts/Muli-SemiBold.eot');
    src: url('/fonts/Muli-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Muli-SemiBold.woff2') format('woff2'),
    url('/fonts/Muli-SemiBold.ttf') format('truetype'),
    url('/fonts/Muli-SemiBold.svg#Muli-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Museo 300';
    src: url('/fonts/Museo300-Regular.eot');
    src: url('/fonts/Museo300-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Museo300-Regular.woff2') format('woff2'),
    url('/fonts/Museo300-Regular.ttf') format('truetype'),
    url('/fonts/Museo300-Regular.svg#Museo300-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Museo 700';
    src: url('/fonts/Museo-700.eot');
    src: url('/fonts/Museo-700.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Museo-700.woff2') format('woff2'),
    url('/fonts/Museo-700.ttf') format('truetype'),
    url('/fonts/Museo-700.svg#Museo-700') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

.transitioning {
    transition: .3s ease all;
}

/* Site CSS */
body {
    scroll-behavior: smooth;
}

.font-museo {
    font-family: 'Museo 700', Sans-Serif;
}

.font-muli {
    font-family: 'Muli';
}

/* Global */
body {
    @apply font-museo;
}

h1, h2 {
    @apply relative text-grey-600 font-museo my-6 ml-4 text-4xl;
}


a {
    @apply cursor-pointer transition-all;
}

.red-btn {
    @apply text-red text-xl lg:text-lg cursor-pointer relative;
}

#services .red-btn {
    @apply top-8 xl:top-0;
}


.red-btn:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -3rem;
    height: 1rem;
    width: 2rem;
    background: url("/img/icones/arrow.svg") no-repeat center;
    background-size: contain;
}

.red-btn:hover:after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: calc( 50% + 1.25rem);
    height: 3rem;
    width: calc(100% + 5rem);
    background: rgba(255, 0, 0, 0.2);
}

.to-top {
    @apply hidden fixed h-16 w-16 flex justify-center items-center;
    bottom: 10%;
    right: 5%;
}


.to-top img {
    @apply h-8 w-8 mx-auto;
}

.to-top:after, .to-top:before {
    content: "";
    @apply absolute w-16 h-16 left-0;


}

.to-top:before {
    top: -2rem;
    background: url("/img/icones/arc-blc.svg") no-repeat center;
    background-size: contain;
    transform: rotate(40deg);

}

.to-top:after {
    bottom: 0;
    background: url("/img/icones/arc.svg") no-repeat center;
    background-size: contain;
    transform: rotate(-140deg);
}


.section {
    @apply p-4 xl:px-56 ;
}

.bouton, .btn, input[type=submit] {
    @apply relative inline-block px-4 py-2 bg-primary text-white cursor-pointer;
}

.bouton:hover, .btn:hover, input[type=submit]:hover {
    @apply bg-secondary-500 text-white;
}

.bords {
    @apply border border-solid border-grey-300 p-4;
}

/* Colorbox */
.colorbox {
    box-shadow: 0px 0px 8px #333;
}

/* Formulaire */
input[type=text], input[type=email], input[type=password], input[type=tel] {
    @apply border border-solid border-blue p-1;
}

.form {
    @apply relative block;
}

.form input[type=text] + label, .form input[type=email] + label, .form input[type=tel] + label, .form input[type=password] + label {
    position: absolute;
    left: 3%;
    top: 1.25rem;
    pointer-events: none;
    width: 100%;
    transform: translateY(-50%);
    transition: transform 200ms ease;
    color: #9a9a9a;
    text-align: left;
}

.form input[type=text]:focus + label, .form input[type=email]:focus + label, .form input[type=tel]:focus + label, .form input[type=password]:focus + label, .form textarea:focus ~ label, .form label.freeze {
    @apply text-secondary-500 font-normal text-xs !important;
    transform: translateY(-1.5rem) !important;
}

/* Cadres */
.box-ligne {
    @apply relative border-b border-solid border-grey-300;
}

.card {
    @apply bg-white shadow-xl mb-4 py-8 px-12;
}

/* Header */
header {
    @apply relative text-center bg-white h-16 lg:h-auto;
    z-index: 100;
}

header ul, header ul li {
    @apply relative list-none p-0;
}

header ul li{
    @apply py-4 lg:py-0
}

header ul li:last-child{
    @apply pb-8 lg:pb-0;
}

header nav {
    @apply bg-white py-8;
}

header a {
    @apply text-grey-600 font-bold font-museo lg:py-0 py-8;
}

header a:hover {
    @apply text-red transitioning;
}

header.scrollingdown {
    @apply shadow-xl transitioning;
}

header.scrollingdown nav {
    @apply py-4 transitioning;
}

header.scrollingdown nav ul {
    @apply mb-0;
}

header.scrollingdown .logo {
    @apply w-20 h-20 transitioning;
}

header ul {
    @apply lg:ml-32 xl:ml-40;
}

header ul.subnav {
    @apply bg-white;
}

.logo-mobile {
    @apply lg:hidden absolute;
    left: 10px;
    top: 5px;
}

.logo-mobile img {
    @apply w-12;
}

.logo{
    @apply hidden;
}


/* Contenu */
#main {
    @apply relative;
}

#intro {
    @apply h-56;
    background: url("/img/bg/AdobeStock_103075712-web.JPG") no-repeat center;
    background-size: cover;

}

.service-graphique {
    @apply w-32 xl:h-96 bg-grey-100 relative;
}

.service-img {
    @apply lg:w-1/3 xl:w-2/5 xl:h-96 xl:pl-8 z-10 bg-white;
}

.service-img img {
    @apply md:h-full md:w-full md:object-cover;
}

.service-text {
    @apply h-96 px-8 bg-grey-100 p-4 lg:w-1/2 xl:w-3/5 xl:pl-12 xl:pt-8;
    height: 33rem;
}

.service-text h3 {
    @apply mb-8 mt-8 lg:mt-0 lg:text-base font-bold text-grey-900 ;
}

.service-text p {
    @apply xl:w-1/2 lg:text-sm text-grey-500;
}
#services h1{
    @apply mx-8 xl:mx-0;
}

#prestations {
    @apply xl:px-40 pb-4 xl:py-24;
}

.presta-container {
    @apply flex flex-col md:grid md:grid-cols-2 lg:grid-cols-3 xxl:flex xxl:flex-row xxl:mb-12 xxl:h-96 items-center xxl:items-end justify-center;
}

.presta-container .presta-box {
    @apply xxl:w-1/5 py-8 px-4 flex flex-col justify-center items-center relative mx-4 overflow-hidden;
}

.presta-box img {
    @apply w-24;
}

.presta-box p:not(.details) {
    @apply text-lg text-center mt-12;
}

.presta-box p.details {
    @apply hidden mb-6 text-white text-center;
}

.presta-box:hover {
    @apply bg-red-500 text-white transitioning;
}

.presta-box:hover p.details {
    @apply text-sm block mb-0 mt-8 transitioning;
}

.presta-box:hover img {
    @apply transitioning;
    filter: invert(1);
}

.presta-box > .white-circle {
    @apply absolute h-12 w-24;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    border: 10px solid transparent;
    border-bottom: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}


.presta-box > .white-circle:first-of-type {
    @apply -top-4 -left-8;
    transform: rotate(135deg);
}

.presta-box > .white-circle:last-of-type {
    @apply -bottom-4 -right-8;
    transform: rotate(-45deg);
}

.presta-box:hover > .white-circle {
    @apply block transitioning;
    border: 10px solid white;
    border-bottom: 0;
}


#certifie {
    background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url("/img/pexels-karolina-grabowska-4239036.jpg") no-repeat center;
    background-size: cover;
    @apply pb-8 xl:py-0 px-8 xl:px-0 xl:px-40 xl:py-16;
}

.pastille {
    background-color: #252026;
}

#certifie h3 {
    @apply mt-6 xl:mt-0 mb-12 xl:mb-0 text-3xl text-black;
}

#certifie .text-content p {
    @apply text-lg;
}

#emploi {
    @apply xl:pt-24 px-8 xl:px-0;
}

#emploi h2{
    @apply ml-0 md:ml-40;
}

.emploi-text {
    @apply px-8 bg-grey-100 p-4 xl:pr-12 xl:pt-8 text-right;
}

.emploi-text h3 {
    @apply mb-8 mt-8 lg:mt-0 lg:text-base font-bold text-grey-900 ;
}

.emploi-text p {
    @apply xl:w-1/2 lg:text-sm text-grey-500;
}


#references {
    @apply xl:pt-24 px-8 xl:px-0;
}

#references h2, #references p {
    @apply xl:pl-40
}

#references h2{
    @apply ml-0;
}

#swiperRef {
    @apply w-full my-8;
}

.swiper-ref_prev {
    transform: rotate(180deg);
}

#swiperRef .swiper-slide img {
    width: 60%;
    margin: 0 auto;
}

@screen sm {
    .service-text {
        @apply h-96;
    }
}

@screen lg {
    header nav {
        @apply pr-0 px-16 bg-transparent;
    }

    header .logo {
        @apply w-32 h-32 absolute bg-white rounded-full block top-0 left-8 xl:left-20 xxl:left-40;
    }

    header .logo img {
        @apply w-32;
    }

    header ul li {
        @apply mr-16 mb-0;
    }

    #swiperRef {
        height: 22rem;
        margin-top: -5rem;
        margin-bottom: 0;
    }

    #swiperRef .swiper-slide img {
        width: auto;
    }

    .swiper-ref_prev, .swiper-ref_next {
        @apply top-8 w-20 h-24;
    }


    .swiper-ref_prev {
        left: 40%;
        transform: rotate(180deg);
    }

    .swiper-ref_next {
        left: 45%;
    }
}

@screen xl {
    header nav {
        @apply py-8;
    }


    header ul li {
        @apply mr-24 mb-0;
    }

    h1, h2 {
        @apply mt-0 ml-0;
        font-size: 4rem;
    }

    #intro {
        @apply h-120;
    }

    #services {
        @apply h-96;
    }

    #services h1 {
        @apply ml-32 pl-8 pb-8 bg-white mb-0;
        z-index: 1;
    }

    .service-graphique > .red-circle {
        @apply absolute w-40 h-40 rounded-full border-red border-solid border-8;
        z-index: 0;
        left: 3rem;
        top: -4rem;
    }

    .swiper-ref_prev {
        left: 41%;
        transform: rotate(180deg);
    }

    .swiper-ref_next {
        left: 46%;
    }
}

@screen xxl {
    header nav {
        @apply px-56;
    }
    .swiper-ref_prev {
        left: 33%;
        transform: rotate(180deg);
    }

    .swiper-ref_next {
        left: 38%;
    }

}

#swiperRef .swiper-slide > div {
    @apply h-full flex justify-center items-center mx-12 lg:mx-8;
}

.swiper-ref_prev, .swiper-ref_next {
    @apply absolute;
}

.swiper-ref_prev:after, .swiper-ref_next:after {
    content: "";
    @apply w-8 h-8 absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url("/img/icones/arrow.svg") no-repeat center;
}

#section-contact {
    @apply xl:px-56 xl:py-24 pt-16 px-4 bg-grey-100 flex flex-col lg:flex-row;
}

#section-contact h2{
    @apply lg:ml-0;
}


.container {
    max-width: 400px;
    width: 100%;
    position: relative;
}

#contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea, #contact button[type="submit"] {
    font: 400 12px/16px "Open Sans", Helvetica, Arial, sans-serif;
}

#contact {
    font-family: 'Muli', Sans-Serif;
}

fieldset {
    @apply relative p-0 w-full mb-8;
    border: medium none !important;
    min-width: 100%;
}

fieldset > span {
    @apply absolute;
    color: red;
    top: 8px;
    left: 10px;
}

#contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea {
    width: 100%;
    border: solid white 1px;
    outline: none;
    background: #FFF;
    margin: 0 0 5px;
    padding: 10px 10px 10px 20px;
}

#contact input[type="text"]:hover, #contact input[type="email"]:hover, #contact input[type="tel"]:hover, #contact input[type="url"]:hover, #contact textarea:hover {
    -webkit-transition: border-color 0.3s ease-in-out;
    -moz-transition: border-color 0.3s ease-in-out;
    transition: border-color 0.3s ease-in-out;
    border: 1px solid #AAA;
}

input:required::placeholder:after {
    content: "*";
    @apply text-red absolute right-0 h-1 w-1;
}

#contact textarea {
    height: 100px;
    max-width: 100%;
}

#contact button[type="submit"] {
    @apply text-red cursor-pointer relative font-museo text-lg;
}

#contact button[type="submit"]:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -3rem;
    height: 1rem;
    width: 2rem;
    background: url("/img/icones/arrow.svg") no-repeat center;
    background-size: contain;
}

#contact button[type="submit"]:hover:after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 65%;
    height: 3rem;
    width: 14rem;
    background: rgba(255, 0, 0, 0.2);
}

#contact button[type="submit"]:active:after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    height: 10px;
    width: 14rem;
    background: rgba(255, 0, 0, 0.2);
}

#contact input:focus, #contact textarea:focus {
    outline: none;
    border: 1px solid #999;
}

::-webkit-input-placeholder {
    color: #888;
}

:-moz-placeholder {
    color: #888;
}

::-moz-placeholder {
    color: #888;
}

:-ms-input-placeholder {
    color: #888;
}

.map-container {
    @apply w-full;
}

.map-container iframe {
    @apply w-full;
}

@screen xl {
    .map-container {
        width: 630px;
    }
}


.coordonnees {
    font-family: "Muli", Sans-Serif;
    @apply text-grey-700;
}


.coordonnees p:first-child {
    @apply text-lg font-bold;
}


#content {
    @apply px-4 py-8;

    & ul {
        @apply list-disc list-inside mb-4;
    }
}

.popup {
    @apply lg:pt-40 hidden fixed top-0 p-8 left-0 bg-grey-900 bg-opacity-95 z-50 grid place-items-center h-screen w-screen overflow-scroll;
}

.popup-content h2 {
    @apply text-xl text-grey-800 mb-8;
}

.popup-content h3 {
    @apply text-lg text-grey-500 mb-4 mt-6;
}

.popup-content p {
    @apply text-grey-700;
}

.popup-content a {
    @apply font-bold;

    &:hover {
        @apply text-red-500;
    }
}

/* Footer */
footer {
    background: #272727;
    @apply text-center;
}

footer a:hover {
    @apply text-red-500 transitioning;
}

footer a:hover > p {
    @apply text-red-500 transitioning;
}

.footer-top .logo-footer {
    @apply w-32 md:w-48;
}

.footer-top div {
    @apply flex flex-col items-center lg:items-start;
}


.footer-top p, .footer-top h4 {
    @apply text-lg text-grey-100 font-bold;
}

.footer-top a, .footer-top span {
    @apply my-4 lg:my-2 text-grey-300 font-muli text-lg;
}

.footer-bottom {
    font-family: 'Muli', Sans-Serif;
}

.footer-bottom p, .footer-bottom span, .footer-bottom a {
    @apply text-xs text-grey-300;
}

.footer-top .lien-interim {
    @apply text-sm lg:text-left pr-4;
    & a {
        @apply text-sm text-secondary;
    }
}


.contenu {
    @apply py-24 w-11/12 sm:w-4/5 mx-auto;
}
/* Responsive */
@screen sm {
}
@screen md {
}
@screen lg {
}
@screen xl {
}
@screen xxl {
}

.offre-content {width: 90%; }

@screen md {
    .offre-content { width: 600px; }
}

@screen lg {
    .offre-content { width: 1000px; }
    .offre-content.small { width: 800px;  }

}

@screen xxl {
    .offre-content { width: 1500px;  }
    .offre-content.small { width: 750px;  }
}

.offre-content h1 {
    margin-top: 0!important;
    margin-left: 0!important;
}

#swiper-offres .offre img {
    transition: 0.12s;
}

#swiper-offres .offre:hover img {
    transform: scale(1.06);
}

.swiper-button-prev.swiper-offres_prev,
.swiper-button-next.swiper-offres_next{
    color: #444;
    transform: scale(0.75);
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    opacity: 0 !important;
}