/*Boutons*/
.btn {
    @apply py-2 px-4 cursor-pointer transition-all;

    &-primary {
        @apply bg-primary-500 hover:bg-primary-700;
    }

    &-secondary {
        @apply bg-secondary-500 hover:bg-secondary-700;
    }

    &_icon {
        @apply relative pl-12;

        &::before {
            content: "";
            @apply absolute left-2 y-center w-6 h-6;
        }
    }
}

/*Alert*/
.alert {
    @apply sticky top-24 text-grey-900 rounded-md px-4 py-3 shadow-md w-11/12 md:w-1/2 xl:w-1/3 mx-auto border-2 mb-4 z-20;

    &-success {
        @apply bg-alert-success-bg border-alert-success-border;
    }

    &-warning {
        @apply bg-alert-warning-bg border-alert-warning-border;
    }

    &-error {
        @apply bg-alert-error-bg border-alert-error-border;
    }

    &-info {
        @apply bg-alert-info-bg border-alert-info-border;
    }
}

/* Bandeau cookie */
#mention_cookies {
    @apply fixed bottom-0 bg-white py-2 px-4 w-full text-sm flex items-center justify-center z-50;
    & a {
        @apply mx-2;
    }
}

/* Pastille notif */
.notification {
    @apply inline-block relative;
    & span {
        @apply absolute grid place-items-center -top-3 -right-3 bg-primary-500 rounded-full text-sm w-7 h-7 p-1;
    }

}

/*Checkbox */
.input-checkbox {
    @apply flex;
    & span {
        @apply relative block w-6 h-6 bg-grey-500 mr-2 cursor-pointer transition-all;
        &::before {
            content: "";
            left: 8px;
            top: 2px;
            width: 8px;
            height: 16px;
            @apply absolute border-white border-b-2 border-r-2 transform rotate-45 transition-all opacity-0;
        }
    }
    & input {
        @apply opacity-0 w-0 h-0;
        &:checked ~ span {
            @apply bg-primary-500;

            &::before {
                @apply opacity-100;
            }
        }
    }
}

/* Radio */
.input-radio {
    @apply flex;
    & span {
        @apply relative block w-6 h-6 bg-grey-500 mr-2 cursor-pointer rounded-full transition-all;
        &::before {
            content: "";
            @apply absolute w-2 h-2 abs-center bg-white rounded-full transition-all opacity-0;
        }
    }
    & input {
        @apply opacity-0 w-0 h-0;
        &:checked ~ span {
            @apply bg-primary-500;

            &::before {
                @apply opacity-100;
            }
        }
    }
}

/* Switch */
.input-switch {
    @apply flex;
    & span {
        @apply relative block w-12 h-6 bg-grey-500 mr-2 cursor-pointer rounded-full transition-all;

        &::before {
            content: "";
            @apply absolute left-1 y-center w-4 h-4 bg-white rounded-full transition-all;
        }
    }
    & input {
        @apply opacity-0 w-0 h-0;
        &:checked ~ span {
            @apply bg-primary-500;

            &::before {
               @apply left-7;
            }
        }
    }
}